import FormDataValidator from 'form-data-validator';
import isEmail from 'validator/lib/isEmail';
import equals from 'validator/lib/equals';

export default class Validation {
	constructor() {
		// Variables
		this.forms = [...document.querySelectorAll('.js-validate')];

		// Functions

		this.init();
	}

	init() {
		this.validateForms();
	}

	validateForms() {
		this.forms.forEach((form) => {
			FormDataValidator.validateForm(form, {
				scrollToFirstError: true,
				parentSelector: '.js-input-wrapper',
				ignoreFields: ['g-recaptcha-response'],
				customTypes: [{
					type: 'email',
					rule: field => isEmail(field.value),
					message: 'dit is geen geldig e-mailadres'
				}],
				rules: [{
					field: '#confirm-new-password',
					rule: field =>
						equals(field.value, form.querySelector(field.dataset.equalTo).value)
				}]
			});
		});
	}
}
