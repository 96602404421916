'use strict';

import Glide from '@glidejs/glide';

/**
 * NativeConsole module
 * @class
 * @author Thomas Van Kerckvoorde <thomas@chilli.be>
 */

export default class Slider{
	/**
	* @constructor
	*/
	constructor(){

		if (window.NodeList && !NodeList.prototype.forEach) {
			NodeList.prototype.forEach = function (callback, thisArg) {
				thisArg = thisArg || window;
				for (var i = 0; i < this.length; i++) {
					callback.call(thisArg, this[i], i, this);
				}
			};
		}

		var sliders = document.querySelectorAll('.js-glide');

		for (var i = 0; i < sliders.length; i++) {
			var glide = new Glide(sliders[i], {
				type: 'carousel',
				autoplay: 10000,
				perView: 1
			});

			glide.mount();
		}

	}
}
