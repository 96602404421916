'use strict';

/* libs */
import 'lazysizes';
import svg4everybody from 'svg4everybody';

/* custom imports */
import NativeConsole from './modules/native-console';
import Navigation from './modules/navigation';
import Slider from './modules/slider';
import Validation from './modules/validation';


/**
 * Chilli App
 * @class
 * @author Thomas Van Kerckvoorde <thomas@chilli.be>
 */

class ChilliApp{

	/**
	 * @constructor
	 */
	constructor(){
		new NativeConsole();
		new Navigation();
		new Validation();


		const scroll = document.querySelector('.js-scroll');

		if( scroll ) {
			scroll.addEventListener('click', function(e) {
				e.preventDefault();
				document.querySelector('.main').scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					inline: 'nearest'
				});
			});
		}



		const galleries = document.querySelectorAll('.js-glide');

		if( galleries.length ) {
			new Slider();
		}

		/* external svg polyfill, https://github.com/jonathantneal/svg4everybody */
		svg4everybody();
	}
}

/**
 * js vanilla equivalent to jquerys $(document).ready -- fires when DOM is ready (assets not yet ready)
 */
window.addEventListener('load', function() {
	new ChilliApp();

	initEvents();

	scrollEvent(0);
});

const initEvents = () => {

	let currentY = 0;

	if(window.innerWidth > 768) {


		let percentScroll = (window.scrollY / window.innerHeight);

		if(percentScroll > .4){
			if(!document.querySelector('.navigation').classList.contains('hide-navigation') && !document.querySelector('.nav-full').classList.contains('navigation--expanded') ){
				document.querySelector('.navigation').classList.toggle('hide-navigation');
			}
		}

		window.addEventListener('scroll', () => {

			scrollEvent(currentY);
			currentY = window.scrollY;

		});

	}

};

const scrollEvent = (currentY) => {

	let percentScroll = (window.scrollY / window.innerHeight);

	if(percentScroll > .4){
		//hide toevoegen
		if(!document.querySelector('.navigation').classList.contains('hide-navigation') && !document.querySelector('.nav-full').classList.contains('navigation--expanded') ){
			document.querySelector('.navigation').classList.toggle('hide-navigation');
		}

		//hide verwijderen
		if(document.querySelector('.alternate-navigation').classList.contains('hide-alternate') && !document.querySelector('.nav-full').classList.contains('navigation--expanded')){
			document.querySelector('.alternate-navigation').classList.toggle('hide-alternate');
		}

		if(currentY > window.scrollY && !document.querySelector('.nav-full').classList.contains('navigation--expanded')){
			//STIJGEN

			//hide verwijderen
			if(document.querySelector('.alternate-navigation').classList.contains('hide-alternate')){
				document.querySelector('.alternate-navigation').classList.toggle('hide-alternate');
			}

		} else {
			//DALEN

			//hide toevoegen
			if(!document.querySelector('.alternate-navigation').classList.contains('hide-alternate') && percentScroll > 1 && !document.querySelector('.nav-full').classList.contains('navigation--expanded')){

				document.querySelector('.alternate-navigation').classList.toggle('hide-alternate');
			}

		}

	} else {
		//terug hide verwijderen
		if(document.querySelector('.navigation').classList.contains('hide-navigation') && !document.querySelector('.nav-full').classList.contains('navigation--expanded')){
			document.querySelector('.navigation').classList.toggle('hide-navigation');
		}

		//hide toevoegen
		if(!document.querySelector('.alternate-navigation').classList.contains('hide-alternate') && !document.querySelector('.nav-full').classList.contains('navigation--expanded')){
			document.querySelector('.alternate-navigation').classList.toggle('hide-alternate');
		}
	}

};
